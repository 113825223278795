import cn from 'classnames';
import { Core } from 'connex-cds';
import { isUndefined } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { usePostMessageListener } from '../../../util/usePostMessageListener';
import { useAppContext } from '../../authenticated-routes/AppContext';
import { CollapsibleSidebar } from './CollapsibleSidebar';
import { FixedSidebar } from './FixedSidebar';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const Sidebar = ({ fixed, children, open }) => {
  const [isOpen, setIsOpen] = React.useState(!!open);
  const [menuConfig, setMenuConfig] = React.useState(null);
  const { appRef } = useAppContext();

  const toggle = React.useCallback(() => {
    setIsOpen(s => !s);
  }, []);

  const SidebarComponent = React.useMemo(() => {
    return fixed ? FixedSidebar : CollapsibleSidebar;
  }, [fixed]);

  const handleMessageEvent = React.useCallback(event => {
    const message = event?.data;
    if (!isUndefined(message?.menu)) {
      setMenuConfig(message?.menu);
    }
  }, []);

  React.useEffect(() => {
    setMenuConfig(null);
  }, [appRef]);

  usePostMessageListener(handleMessageEvent);

  return (
    <Styled className={cn('cx-sidebar', { open: isOpen })}>
      <SidebarComponent menuConfig={menuConfig} isOpen={isOpen} onTrigger={toggle} />
      <div className="content">{children}</div>
    </Styled>
  );
};
