import cn from 'classnames';
import { useEntityContext, User } from 'connex-cds';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useClickOutside } from '../../../../util/useClickOutside';
import { useAppContext } from '../../../authenticated-routes/AppContext';
import { AppIcons } from './app-icons/AppIcons';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const AppSwitcher = () => {
  const { service } = useParams();
  const { appRef, setAppRef } = useAppContext();
  const [isOpen, setIsOpen] = React.useState(!service);
  const navigate = useNavigate();
  const { user } = User.useUserContext();
  const { reset } = useEntityContext();

  const toggle = React.useCallback(() => {
    setIsOpen(s => !s);
  }, []);

  const handleChange = React.useCallback(
    val => {
      reset();
      setAppRef(val);
      navigate(`app/${val}`);
    },
    [navigate, reset, setAppRef]
  );

  React.useEffect(() => {
    setAppRef(s => {
      if (s !== service) {
        return service;
      }
      return s;
    });
  }, [service, setAppRef]);

  React.useEffect(() => {
    const handler = e => {
      if (isOpen && e.key === 'Escape') {
        setIsOpen(false);
      }
    };
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [isOpen]);

  const ref = useClickOutside(() => setIsOpen(false));

  return (
    <>
      <Styled className={cn('app-switcher')} $apps={user?.apps} ref={ref}>
        <div
          className={cn('overlay', { open: isOpen })}
          onClick={e => {
            console.log('click a');
            setIsOpen(false);
          }}
        />
        <div className={cn('header-icon', { active: isOpen })} onClick={toggle}>
          <i className="fa-sharp fa-solid fa-grid" />
          <div className={cn('app-tray', { active: isOpen })}>
            <div className="app-tray-inner">
              <div className="tray-title">
                <div className="tray-title-text">App Tray</div>
              </div>
              <AppIcons apps={user.apps} onClick={handleChange} />
            </div>
          </div>
        </div>
      </Styled>
    </>
  );
};
