import { Core, Form, Drawer, User } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import tabs from './tabs';
import { useUserProfile } from './useUserProfile';
import { formConfig } from './form-config';

const { FormProvider } = Form;

const Styled = styled.div`
  .ant-tabs {
    color: var(--color-text-page);
  }
`;

export const Editor = () => {
  const { user } = User.useUserContext();

  const { closeDrawer } = Drawer.useDrawerContext();
  const { userData, getUserData, busy } = useUserProfile();

  React.useEffect(() => {
    getUserData(user?.crn);
  }, [user?.crn, getUserData]);

  const handleSaveClick = React.useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  return (
    <Styled className={cn('editor')}>
      <Core.Spinner spin={busy}>
        <FormProvider config={formConfig} initialValues={userData}>
          <Core.Tabs config={tabs} busy={busy} onSave={handleSaveClick} />
        </FormProvider>
      </Core.Spinner>
    </Styled>
  );
};
