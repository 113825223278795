import { Localization, useEntityContext } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useAppContext } from '../views/authenticated-routes/AppContext';

const Styled = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  margin-top: 30%;
  font-size: 1.3rem;
`;

const SelectEntity = () => {
  return (
    <Styled>
      <div>
        <Localization.Translate stringId="selectCompany" data-testid="select-company" />
      </div>
    </Styled>
  );
};

export const EnforceEntityRequirement = ({ children }) => {
  const { entityRef } = useEntityContext();
  const { isEntityPickerRequired } = useAppContext();

  if (!entityRef && isEntityPickerRequired) {
    return <SelectEntity />;
  }

  return children;
};
