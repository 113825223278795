import cn from 'classnames';
import { Localization, useEntityContext, usePostMessageContext } from 'connex-cds';
import { find } from 'lodash';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { usePostMessageListener } from '../../../../util/usePostMessageListener';
import { useAppContext } from '../../../authenticated-routes/AppContext';
import { DynamicMenuItem } from './DynamicMenuItem';
import style from './style';
import syncMenuToLocation from './syncMenuToLocation';

const Styled = styled.div`
  ${style}
`;

const useConfig = () => {
  const { appRef } = useAppContext();

  const getConfig = React.useCallback(
    ({ config, entityRef, enabled }) => {
      if (!config) {
        return undefined;
      }
      const item = {
        ...config,
        icon: <i className={config.icon || ''} />,
        label: (
          <Localization.Translate
            stringId={`${appRef}__${config.labelStringId}`}
            data-testid={`menu-item-${config.id}`}
          />
        ),
        disabled: enabled && !entityRef,
      };

      return item;
    },
    [appRef]
  );

  return getConfig;
};

export const DynamicMenu = ({ config, isOpen }) => {
  const { entityRef, enabled } = useEntityContext();
  const { sendMessage } = usePostMessageContext();
  const { currentFeature, setCurrentFeature } = useAppContext();
  const getConfig = useConfig();

  const { isEntityPickerRequired } = useAppContext();

  const [, setIframeLocation] = React.useState();

  const preparedConfig = React.useMemo(() => {
    if (!config) {
      return [];
    }
    return config?.map?.(configItem => getConfig({ config: configItem, entityRef, enabled }));
  }, [config, enabled, entityRef, getConfig]);

  const handleMessageEvent = React.useCallback(
    event => {
      const message = event?.data;

      if (message?.locationChange) {
        const newLocation = message.locationChange;

        setIframeLocation(newLocation);

        // Set the Menu - if the entityPicker isn't required, don't send the entity
        // because the entityRef won't be part of the app's routes.
        const targetMenuItem = syncMenuToLocation(
          newLocation,
          preparedConfig,
          isEntityPickerRequired ? entityRef : undefined
        );

        if (targetMenuItem) {
          setCurrentFeature(targetMenuItem?.item);
        } else {
          setCurrentFeature(undefined);
        }
      }
    },
    [entityRef, isEntityPickerRequired, preparedConfig, setCurrentFeature]
  );

  usePostMessageListener(handleMessageEvent);

  const handleMenuItemClick = React.useCallback(
    id => {
      const targetItem = find(config, { id });
      sendMessage({ menuItemClicked: targetItem });
    },
    [config, sendMessage]
  );

  return preparedConfig ? (
    <Styled className={cn('dynamic-menu-items', { isOpen })}>
      {preparedConfig.map(item => (
        <DynamicMenuItem
          item={item}
          onClick={handleMenuItemClick}
          className={cn({ active: currentFeature?.id === item.id })}
        />
      ))}
    </Styled>
  ) : null;
};
