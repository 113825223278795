import { css } from 'styled-components';

export default css`
  //z-index: 10;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    padding-top: 37px;
    min-height: 150px;
    color: var(--color-text-page); //#605d62; //

    .left {
      .feature-name {
        font-weight: 600;
        font-size: 48px;
        line-height: 56px;
        top: -100px;
        position: absolute;
        transition: top 0.1s;
      }
      .entity-switcher {
        position: absolute;
        top: 50px;
      }
      &.visible {
        .feature-name {
          top: 37px;
        }
        .entity-switcher {
          top: 93px;
        }
      }
    }

    .right {
      flex: 1;
      text-align: right;
      .header-icon {
        font-size: 28px;
        height: 69px;
        width: 69px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        cursor: pointer;
        transition: background-color 0.3s;
        &:hover {
          background-color: var(--sidebar-menu-icon-background-color-active);
        }
        &.active {
          background-color: var(--sidebar-menu-icon-background-color-active);
        }
      }
      .center-aligned {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
      }
    }
  }
`;
