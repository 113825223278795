import { getCachedItem, useEntityContext, User } from 'connex-cds';
import { find } from 'lodash';
import React, { useContext, useReducer } from 'react';
import { useQuery } from 'react-query';
import { apps } from '../../api';
// import knownServices from '../../service-iframe/knownServices';

const AppContext = React.createContext();

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error(`useAppContext cannot be rendered outside of the AppContext context provider`);
  }
  return context;
};

const AppContextProvider = ({ children }) => {
  const [appRef, setAppRef] = React.useState(null);
  const [appEntities, setAppEntities] = React.useState();
  const [iframeLoading, setIframeLoading] = React.useState(false);
  const [currentFeature, setCurrentFeature] = React.useState();

  React.useEffect(() => {
    setCurrentFeature(undefined);
  }, [appRef]);

  const { user } = User.useUserContext();
  const appsQuery = useQuery('apps', apps.listApps);

  const appConfig = React.useMemo(() => {
    if (!appRef || !appsQuery.isSuccess) return;
    return find(appsQuery.data, { crn: appRef });
  }, [appRef, appsQuery.data, appsQuery.isSuccess]);

  React.useEffect(() => {
    // TODO: Get entities allowed for app/user.  This might end up being a separate API call or
    // provided when the user logs in (user.apps[{...appStuff, entities}])

    /* key: 'appEntities', userRef, appRef
      await queryClient.fetchQuery({
        queryKey: ['setup', entityRef, field.listTypeId],
        queryFn: () => masterData.getMasterData({ entityRef, typeId: field.listTypeId }),
        staleTime: Infinity,
      })
     */

    if (['admin', 'settings'].includes(appRef)) {
      setAppEntities(undefined);
    } else {
      setAppEntities(user?.entities);
    }
  }, [appRef, user?.entities]);

  const isEntityPickerRequired = React.useMemo(() => {
    return appRef && appEntities?.length > 0;
  }, [appEntities?.length, appRef]);

  return (
    <AppContext.Provider
      value={{
        appRef,
        setAppRef,
        appConfig,
        appEntities,
        iframeLoading,
        setIframeLoading,
        isEntityPickerRequired,
        currentFeature,
        setCurrentFeature,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
