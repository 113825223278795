import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { Auth } from 'connex-cds';
import { ProductionWarning } from '../authenticated-layout/header/ProductionWarning';
import AppContextProvider from './AppContext';

const { LoginAuthenticated } = Auth;

const AuthenticatedRoutes = () => {
  const location = useLocation();
  return (
    <LoginAuthenticated toLogin={<Navigate to="/login" state={{ from: location }} replace />}>
      <AppContextProvider>
        <ProductionWarning />
        <Outlet />
      </AppContextProvider>
    </LoginAuthenticated>
  );
};

export default AuthenticatedRoutes;
