import common from '../common';
import errors from './errors';
import themes from './theme';

export default {
  ...common,
  'save-password': 'Save Password',
  requestReset: 'Request Reset',
  'forgot-password-instructions':
    'A verification code has been sent to you.  Enter it below and select a new password.',
  'password-requirements':
    'Your password must be at least 8 characters, contain at least 1 uppercase, 1 lowercase, 1 number and 1 special character.',

  success: 'Success',
  'password-reset-success': 'Your password was reset successfully.',
  selectCompany: 'Please select a company',
  button: 'Button Text',
  save: 'Save',
  text: 'ISO 3166-1 (Codes for the representation of names of countries and their subdivisions – Part 1: Country codes) is a standard defining codes for the names of countries, dependent territories, and special areas of geographical interest. It is the first part of the ISO 3166 standard published by the International Organization for Standardization.',

  username: 'Username',
  password: 'Password',
  login: 'Login',
  required: 'Required',

  light: 'Light',
  dark: 'Dark',

  rememberMe: 'Remember Me',

  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Privacy Policy',

  customer: 'Customer',
  project: 'Project',

  isBillable: 'Billable',
  dispatchDateTime: 'Date',
  orderId: 'Order',
  ticketId: 'Ticket',
  location: 'Location',
  vehicleTypeName: 'Vehicle',
  product: 'Product',
  salesTax: 'Sales Tax',
  totalPrice: 'Total',
  extendedPrice: 'Ext',
  destination: 'Destination',
  productId: 'Product ID',
  statusCode: 'Status',
  vehicleTypeId: 'Vehicle Type ID',
  locationId: 'Location ID',
  customerId: 'Customer ID',
  projectId: 'Project ID',
  dispatchTime: 'Time',

  showAll: 'Show All',
  hideAll: 'Hide All',
  undoChanges: 'Undo Changes',
  resetColumns: 'Reset Columns',

  displayRowCount: `Displaying {count, plural, 
    =0 {0 rows} 
    one {# row} 
    other {# rows} 
  }`,

  columnFilters: 'Column Filters',

  visible: 'Visible',
  hidden: 'Hidden',

  topSection: 'Top Section',

  itemDetail: 'Item Detail',

  quantity: 'Quantity',

  unloadingMethod: 'Unloading Method',

  time: 'Time',
  eventStatus: 'Status',

  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,

  LTR: `{value, plural,
    =0 {0 Liters}
    one {1 Liter}
    other {# Liters}
  }`,

  TO: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,

  ITEM: `{value, plural,
    =0 {0 Items}
    one {1 Item}
    other {# Items}
  }`,

  YDQ_short: `{value, plural,
    =0 {0 CY}
    one {1 CY}
    other {# CY}
  }`,

  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    one {1 Cubic Yard}
    other {# Cubic Yards}
  }`,

  mixIdLabel: 'Mix ID/Description',
  orderedQtyLabel: 'Ordered QTY',
  loadedQtyLabel: 'Loaded QTY',

  END_UNLOADING: 'Finish Unload',
  IN_YARD: 'In Yard',
  TO_JOB: 'Left Plant',
  LEAVE_JOB: 'Leave Job',
  CREATED: 'Ticketed',
  UNLOADING: 'Start Unload',
  ARRIVE_JOB: 'Arrive Site',

  plantName: 'Plant Name',
  plantAddress: 'Plant Address',
  loadingScheduleStart: 'Loading Scheduled Start Date and Time',

  deliveryAddress: 'Delivery Address',
  phoneNumber: 'Phone Number',
  unloadingScheduleStart: 'Unloading Scheduled Start Date and Time',
  startToFinishUnloadingTime: 'Unloading Start - Finish Date and Time',

  deliveryInformation: 'Delivery Information',
  next: 'Next',

  material: 'Material',
  loadingPoint: 'Loading Point',
  unloadingPoint: 'Unloading Point',

  camera: 'Camera',
  comment: 'Comment',

  sapDeliveryNumber: 'SAP Delivery number (BCC Number)',
  salesOrderNumber: 'Sales order number and item line',
  wcDateTime: 'Date and time W/C contact',
  po: 'PO Number',
  orderedByName: 'Order placed by name',
  orderedByPhone: 'Order placed by phone number',
  resourceId: 'Resource ID',
  totalLoadedQty: 'Total loaded qty including this load',
  spacing: 'Load space',
  deliveryFlow: 'Delivery flow',
  timeOnSite: 'Total on site time/ordered unloading time',
  salesText: 'Sales text (material ID)',
  strengthClass: 'Strength class',
  environmentExposure: 'Environment exposure',
  metExposureClasses: 'Met exposure classes',
  dmax: 'Dmax',
  standardCert: 'Standard (certification)',
  workabilityTarget: 'Customer workability target',
  stoneQuality: 'Stone Quality',
  sandQuality: 'Sand Quality',
  targetWC: 'Target W/C ratio from recipe',
  controlClass: 'Control Class',
  chlorideClass: 'Chloride Class',
  typeAndStrength1: 'Type of cement and strengthclass 1',
  typeAndStrength2: 'Type of cement and strengthclass 2',
  typeOfAddition: 'Type of addition',
  typeOfAdmixture: 'Type of admixture',
  orderedTemp: 'Ordered Temperature',
  fibers1: 'Type and content of fibers 1',
  fibers2: 'Type and content of fibers 2',
  calculatedMaturityTime: 'Calculated maturity time from W/C contact time',

  generalDelivery: 'General Delivery Information',
  materialInfo: 'Material Information',

  cardTitle: 'Card Title',
  cardSubtitle: 'Card Subtitle',
  cardBody: 'Card Body',

  alertTitle: 'The Old Man and the Sea',
  alertMessage:
    'He was an old man who fished alone in a skiff in the Gulf Stream and he had gone eighty-four days now without taking a fish.',

  water: 'Water',
  materialService: 'Material / Service',
  returnedMaterial: 'Returned Material',

  waterAdded: 'Water Added',
  concreteOnTruck: 'Concrete on Truck',
  reason: 'Reason',
  customerAddedWater: 'Customer Added Water',
  addWater: 'Add Water',
  addedBy: 'Added By',

  addMaterialService: 'Add Material/Service',
  customerAddedProduct: 'Customer Added Product',

  addReturnedMaterial: 'Add Returned Material',
  leftover: 'Leftover',

  submitAcceptance: 'Submit Acceptance',
  customerAcceptance: 'Customer Acceptance',

  materialSummary: 'Material Summary',
  mix: 'Mix',
  ordered: 'Ordered',
  loaded: 'Loaded',

  driverComment: 'Driver Comment',
  customerComment: 'Customer Comment',
  editComment: 'Edit Comment',
  addComment: 'Add Comment',
  additionalInfo: 'Additional Info',
  onBoard: 'On Board',
  driver: 'Driver',
  commentInstruction: 'Comment Instruction',
  signatureInstruction: 'Signature Instruction',
  customerSignature: 'Customer Signature',

  general: 'General',
  tabTwoStringId: 'Tab Two',
  tabThreeStringId: 'Tab Three',

  status: 'Status',
  updateStatusTime: 'Update Status Time',
  delete: 'Delete',
  cancel: 'Cancel',
  back: 'Back',
  add: 'Add',

  id: 'ID',
  name: 'Name',
  invoiceable: 'Invoiceable',
  provideSignature: 'Provide Signature',
  ticket: 'Ticket',
  details: 'Details',
  qrCode: 'QR',

  driverAcceptanceReason: 'Reason',
  driverAcceptance: 'Driver Acceptance',

  'payment-method': 'Payment Methods',
  'add-payment-method': 'Add Payment Method',

  'cancel-reason-code': 'Cancel Reasons',
  'add-cancel-reason-code': 'Add Reason',

  companies: 'Companies',
  profiles: 'Profiles',
  logout: 'Logout',

  online: 'Online',
  offline: 'Offline',

  mt: 'Mobile Ticket',
  admin: 'Platform Admin',

  profileEditor: 'Profile Editor',

  firstname: 'First Name',
  lastname: 'Last Name',
  email: 'Email Address',
  mobilePhone: 'Mobile Phone',

  currentPassword: 'Current Password',
  oldPassword: 'Current Password',
  newPassword: 'New Password',
  newPasswordVerify: 'New Password (Verify)',
  passwordsCantMatch: 'New password cannot be the same as current password!',
  passwordsDontMatch: "Passwords don't match!",
  passwordRequirements:
    'New password must:\n' +
    '\n' +
    'Be between 8 and 99 characters long.\n' +
    'Include 1 or more numeric characters.\n' +
    'Include 1 or more lower case letters.\n' +
    'Include 1 or more upper case letters.\n' +
    'Include 1 or more special characters.',

  passwordMinCharacters: 'Password must contain 8 or more characters.',
  passwordMaxCharacters: 'Password cannot contain more than 99 characters.',
  passwordLowerCase: 'Password must include 1 or more lower case letter.',
  passwordUpperCase: 'Password must include 1 or more upper case letter.',
  passwordNumber: 'Password must include 1 or more numeric character.',
  passwordSpecialCharacter: 'Password must include 1 or more special character.',

  forgotPassword: 'Forgot Password',
  forgotPasswordInstructions: 'Instructions',
  code: 'Verification Code',

  changePassword: 'Change Password',
  'change-password-success': 'Your password was successfully changed.',
  'change-password-error':
    'Your password could not be changed.  Please make sure your current password is correct and try again. ',
  'forgetMe-success': 'You have been forgotten.',
  'forgetMe-error': 'An error has occurred.  Please make sure your password is correct and try again.',
  'forget-me-warning':
    '<p>This action will remove all your personally identifiable information from our systems after which, you will be logged out and unable to log back in. </p>  If you wish to proceed, please enter your password below.',
  apps: 'Apps',
  settings: 'Profile',
  lastName: 'Last Name',
  firstName: 'First Name',
  forgetMe: 'Forget Me',
  errors,
  themes,
};
