import cn from 'classnames';
import { Theme } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useAppContext } from '../../authenticated-routes/AppContext';
import { DynamicMenu } from './dynamic-menu/DynamicMenu';
import logo from '../../../assets/cai_logo_2c_white.svg';
import { ThemeToggle } from './theme-toggle/ThemeToggle';

const Styled = styled.div`
  .svg-logo {
    img {
      position: absolute;
      top: 20px;
      left: 30px;
      height: 30px;
    }
  }
`;

export const CollapsibleSidebar = ({ isOpen, menuConfig, onTrigger }) => {
  const handleToggle = React.useCallback(() => {
    onTrigger();
  }, [onTrigger]);

  const appContext = useAppContext();

  return (
    <Styled className={cn('sidebar collapsible-sidebar', { visible: !!appContext?.appRef })}>
      <div className="outer">
        <div className="inner">
          <div className="logo-title">
            <div className="svg-logo">
              <img src={logo} />
            </div>
            <div className="title">
              <div className="title-inner">{appContext?.appConfig?.name || ''}</div>
            </div>
          </div>

          <div className="dynamic-menu-section">
            <DynamicMenu config={menuConfig} isOpen={isOpen} />
          </div>
          <div className="bottom">
            <ThemeToggle isOpen={isOpen} />
          </div>
        </div>
        <div className="toggle" onClick={handleToggle} data-testid="sidebar-toggle">
          <i className="icon fa-solid fa-chevron-left" />
        </div>
      </div>
    </Styled>
  );
};
