import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { EnforceEntityRequirement } from '../service-iframe/EnforceEntityRequirement';
import { ServiceIframe } from '../service-iframe/ServiceIframe';
import AuthenticatedLayout from '../views/authenticated-layout/AuthenticatedLayout';
import AuthenticatedRoutes from '../views/authenticated-routes/AuthenticatedRoutes';
import DefaultLandingPage from '../views/DefaultLandingPage';
import PublicLayout from '../views/public-layout/PublicLayout';
import { Eula } from '../views/unauthenticated/Eula';
import { ForgotPassword } from '../views/unauthenticated/forgot-password/ForgotPassword';
import Login from '../views/unauthenticated/login/Login';
import Privacy from '../views/unauthenticated/Privacy';
import { ErrorBoundary } from './ErrorBoundary';

export const BaseRoutes = () => {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route path="/eula" element={<Eula />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotPassword/*" element={<ForgotPassword />} />
      </Route>
      <Route element={<AuthenticatedRoutes />}>
        <Route element={<AuthenticatedLayout />}>
          <Route path="/" element={<DefaultLandingPage />} exact />
          <Route path="/app/*" element={<ErrorBoundary />}>
            <Route path="" element={<DefaultLandingPage />} />
            <Route
              path=":service/*"
              element={
                <EnforceEntityRequirement>
                  <ServiceIframe />
                </EnforceEntityRequirement>
              }
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
