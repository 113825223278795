import cn from 'classnames';
import { Drawer } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useAppContext } from '../../authenticated-routes/AppContext';
import { Profile } from '../profile/Profile';
import { AppSwitcher } from './app-switcher/AppSwitcher';
import { EntitySwitcher } from './entity-switcher/EntitySwitcher';
import { ProductionWarning } from './ProductionWarning';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const Header = () => {
  const { isEntityPickerRequired, currentFeature } = useAppContext();

  return (
    <Styled>
      <Drawer.DrawerProvider>
        <div className="header">
          <div className={cn('left', { visible: !!currentFeature?.id })}>
            <div className={cn('feature-name')}>{currentFeature?.label}</div>
            {isEntityPickerRequired ? <EntitySwitcher /> : null}
          </div>
          <div className="right">
            <div className="center-aligned">
              <AppSwitcher />
              <div className="chat-bubble">
                <i className="header-icon fa-regular fa-comment-dots" />
              </div>
              <div className="notification-bell">
                <i className="header-icon fa-regular fa-bell"></i>
              </div>
              {/*<Button type="primary" onClick={handleClick} stringId="logout" data-testid="logout-button" />*/}
              <Profile />
            </div>
          </div>
        </div>
      </Drawer.DrawerProvider>
    </Styled>
  );
};

export default Header;
